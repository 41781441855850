<template>
  <section class="section is-main-section">
    <div class="table-container">
      <b-table
        :data="contracts"
        :loading="loading"
        striped
        hoverable
        bordered
        paginated
        backend-pagination
        backend-filtering
        backend-sorting
        pagination-size="is-small"
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        @filters-change="filtersChange"
      >
        <template v-for="column in columns">
          <b-table-column :key="column.id" v-bind="column" :sortable="column.sortable">
            <template v-if="column.searchable && !column.numeric" #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
              />
            </template>
            <template v-slot="props">
              {{ column.render ? column.render(props.index, props.row, column.field) : props.row[column.field] }}
            </template>
          </b-table-column>
        </template>

        <b-table-column field="billing_currency" label="Billing Currency" sortable searchable>
         <template #searchable="props">
            <b-select placeholder="Select ..." v-model="props.filters[props.column.field]" size="is-small" expanded>
              <option
                  v-for="option of currencyOprions"
                  :value="option"
                  :key="option">
                  {{ option }}
              </option>
            </b-select>
          </template>
          <template v-slot="props">
          {{ props.row.billing_currency }}
          </template>
        </b-table-column>

        <b-table-column field="billing_period" label="Billing Period " sortable searchable>
         <template #searchable="props">
            <b-select placeholder="Select ..." v-model="props.filters[props.column.field]" size="is-small" expanded>
              <option
                  v-for="option of periodOprions"
                  :value="option"
                  :key="option">
                  {{ option }}
              </option>
            </b-select>
          </template>
          <template v-slot="props">
          {{ props.row.billing_period }}
          </template>
        </b-table-column>

        <b-table-column field="actions" label="Actions" v-if="isAdmin" v-slot="props">
          <router-link :to="{ name: 'contract', params: { id: props.row.id }}">
            <b-button size="is-small" title="Detail">
              <b-icon icon="eye" size="is-small"/>
            </b-button>
          </router-link>

          <router-link :to="{ name: 'contract-report', params: { id: props.row.id, reportId: 'preview' }}">
            <b-button size="is-small" title="Preview report">
              <b-icon icon="chart-box-outline" size="is-small"/>
            </b-button>
          </router-link>
        </b-table-column>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>Nothing here.</p>
            </div>
          </section>
        </template>

        <template slot="bottom-left">
          <b-button
            tag="router-link"
            v-if="isAdmin"
            :to="{ name: 'contract-add' }"
            type="is-success"
            icon-left="plus-box"
            >Add Contract</b-button
          >

          <b-button v-on:click="fetch" type="is-info" icon-left="refresh" outlined style="margin-left: 100px"
            >Refresh data</b-button
          >
        </template>
      </b-table>
    </div>
  </section>
</template>
<script>
import {
  isEqual, pickBy, assign, concat
} from 'lodash';
import variable from '@/variable';
import moment from 'moment';

export default {
  name: 'contracts',
  data() {
    return {
      contracts: [],
      total: 0,
      page: 1,
      perPage: 20,
      sortField: 'name',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      loading: false,
      offset: 0,
      params: {},
      periodOprions: concat('', variable.periodList),
      currencyOprions: concat('', variable.currencyList),
      columns: [
        {
          field: 'index',
          label: 'Index',
          render: (index) => (this.sortOrder === 'asc' ? index + this.offset + 1 : this.total - index - this.offset),
        },
        {
          field: 'id',
          label: 'ID',
        },
        {
          field: 'name',
          label: 'Name',
          searchable: true,
          sortable: true,
        },
        {
          field: 'owner',
          label: 'Internal Owner',
          searchable: true,
          sortable: true,
        },
        {
          field: 'customer_name',
          label: 'Customer Name',
          searchable: true,
          sortable: true,
        },
        {
          field: 'vat_id',
          label: 'VAT ID',
          searchable: true,
          sortable: true,
        },
        {
          field: 'effective_since',
          label: 'Effective Since',
          render: (index, row, field) => (new moment(row[field])).format('YYYY-MM-DD'),
          sortable: true,
        },
        {
          field: 'effective_until',
          label: 'Effective Until',
          render: (index, row, field) => (new moment(row[field])).format('YYYY-MM-DD'),
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      const limit = this.perPage;
      const offset = (this.page - 1) * this.perPage;

      const params = assign(
        {
          sort_field: this.sortField,
          sort_order: this.sortOrder,
          limit,
          offset,
        },
        this.params
      );

      const res = await this.$http.get('/v1/billing/contracts', { params });

      this.contracts = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.offset = offset;
      this.loading = false;
    },
    filtersChange(filters) {
      const params = pickBy(filters, (v) => v.length > 2);
      if (!isEqual(params, this.params)) {
        this.params = params;
        this.fetch();
      }
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
  },
};
</script>
