<template>
  <div>
    <div class="title is-3">
      Preview Report for Contract
      <router-link :to="{ name: 'contract', params: { id: report.contract_id } }">
        {{ report.contract_name }}
      </router-link>
    </div>

    <div class="title is-5">Vat ID: {{ report.vat_id }}</div>
    <div class="title is-5">Billing Period: {{ report.billing_period }}</div>
    <div class="title is-4">Price: {{ report.total_price }} {{ report.billing_currency }}</div>

    <div class="title is-4">Summary Organization</div>

    <table class="table is-bordered is-striped is-hoverable">
      <thead>
        <tr>
          <th>Organization</th>
          <th>HARDWARIO Cloud Price</th>
          <th>HARDWARIO Insight Price</th>
          <th>HARDWARIO Rental Price</th>
          <th>Billing Currency</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="org in report.summary.organizations" :key="org.id">
          <td>
            <router-link :to="{ name: 'organization', params: { id: org.id } }">
              {{ org.name }}
            </router-link>
          </td>
          <td>{{ org.cloud_price }}</td>
          <td>{{ org.insight_price }}</td>
          <td>{{ org.rental_price }}</td>
          <td>{{ report.billing_currency }}</td>
        </tr>
      </tbody>
      <tfoot v-if="report.summary.total">
        <tr>
          <th>Total</th>
          <th>{{ report.summary.total.cloud_price }}</th>
          <th>{{ report.summary.total.insight_price }}</th>
          <th>{{ report.summary.total.rental_price }}</th>
          <th>{{ report.billing_currency }}</th>
        </tr>
      </tfoot>
    </table>

    <div class="title is-4">Summary Service</div>

    <b-table v-if="allOrg.cloud_service.length" :data="allOrg.cloud_service" striped hoverable bordered>
      <b-table-column field="organization_name" label="Organization Name" sortable v-slot="props">
        <router-link :to="{ name: 'organization', params: { id: props.row.organization_id } }">
          {{ props.row.organization_name }}
        </router-link>
      </b-table-column>
      <b-table-column field="name" label="HARDWARIO Cloud Service" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="price" label="HARDWARIO Cloud Price" sortable v-slot="props">
        {{ props.row.price }}
      </b-table-column>
      <b-table-column field="count" label="Count" sortable v-slot="props">
        {{ props.row.count }}
      </b-table-column>
      <b-table-column field="total" label="Total" sortable v-slot="props">
        {{ props.row.total }}
      </b-table-column>
      <b-table-column field="currency" label="Billing Currency" sortable>
        {{ report.billing_currency }}
      </b-table-column>
    </b-table>

    <b-table v-if="allOrg.insight_service.length" :data="allOrg.insight_service" striped hoverable bordered>
      <b-table-column field="organization_name" label="Organization Name" sortable v-slot="props">
        <router-link :to="{ name: 'organization', params: { id: props.row.organization_id } }">
          {{ props.row.organization_name }}
        </router-link>
      </b-table-column>
      <b-table-column field="name" label="HARDWARIO Insight Service" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="price" label="HARDWARIO Insight Price" sortable v-slot="props">
        {{ props.row.price }}
      </b-table-column>
      <b-table-column field="count" label="Count" sortable v-slot="props">
        {{ props.row.count }}
      </b-table-column>
      <b-table-column field="total" label="Total" sortable v-slot="props">
        {{ props.row.total }}
      </b-table-column>
      <b-table-column field="currency" label="Billing Currency">
        {{ report.billing_currency }}
      </b-table-column>
    </b-table>

    <b-table v-if="allOrg.rental_service.length" :data="allOrg.rental_service" striped hoverable bordered>
      <b-table-column field="organization_name" label="Organization Name" sortable v-slot="props">
        <router-link :to="{ name: 'organization', params: { id: props.row.organization_id } }">
          {{ props.row.organization_name }}
        </router-link>
      </b-table-column>
      <b-table-column field="name" label="HARDWARIO Rental Service" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="price" label="HARDWARIO Rental Price" sortable v-slot="props">
        {{ props.row.price }}
      </b-table-column>
      <b-table-column field="count" label="Count" sortable v-slot="props">
        {{ props.row.count }}
      </b-table-column>
      <b-table-column field="total" label="Total" sortable v-slot="props">
        {{ props.row.total }}
      </b-table-column>
      <b-table-column field="currency" label="Billing Currency">
        {{ report.billing_currency }}
      </b-table-column>
    </b-table>

    <div class="mb-5"></div>

    <div class="title is-4">Billable devices</div>
    <ReportTable
      :data="report.billable"
      :loading="loading"
      :billing_period="report.billing_period"
      :billing_currency="report.billing_currency"
    >
    </ReportTable>

    <div class="title is-4">Nonbillable devices</div>
    <ReportTable
      :data="report.nonbillable"
      :loading="loading"
      :billing_period="report.billing_period"
      :billing_currency="report.billing_currency"
    >
    </ReportTable>

    <!-- <b-button v-on:click="downloadCSV"
            type="is-link"
            icon-left="download"
    >Download Report CSV</b-button> -->
  </div>
</template>

<script>
import ReportTable from './ReportTable.vue';

export default {
  components: { ReportTable },
  data() {
    return {
      loading: false,
      report: {
        billable: [],
        nonbillable: [],
        summary: {},
        total_price: null,
      },
      columns: [
        // { label: 'name', field: 'Name' },
        // { label: 'price Interval', field: 'group_name' },
        // { label: 'Device Label', field: 'organization_name' },
      ],
    };
  },
  mounted() {
    this.fetch();
  },
  computed: {
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
    allOrg() {
      const { organizations } = this.report.summary;
      const cloud_service = [];
      const insight_service = [];
      const rental_service = [];
      if (organizations && organizations.length > 0) {
        for (let i = 0; i < organizations.length; i += 1) {
          const org = organizations[i];
          for (let j = 0; j < org.cloud_service.length; j += 1) {
            cloud_service.push({
              organization_id: org.id,
              organization_name: org.name,
              ...org.cloud_service[j],
            });
          }
          for (let j = 0; j < org.insight_service.length; j += 1) {
            insight_service.push({
              organization_id: org.id,
              organization_name: org.name,
              ...org.insight_service[j],
            });
          }
          for (let j = 0; j < org.rental_service.length; j += 1) {
            rental_service.push({
              organization_id: org.id,
              organization_name: org.name,
              ...org.rental_service[j],
            });
          }
        }
      }
      return {
        cloud_service,
        insight_service,
        rental_service,
      };
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      const { data } = await this.$http.post(`/v1/billing/contract/${this.$route.params.id}/report`, {
        preview: true,
      });
      this.report = data;
      this.loading = false;
    },
    downloadCSV() {
      window.open(`${this.baseApiUrl}/v1/billing/report-csv?token=${this.$auth.getToken()}`, '_blank');
    },
  },
};
</script>
