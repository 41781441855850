<template>
  <div class="table-container">
    <b-table :data="data" :loading="loading" striped hoverable bordered>
      <b-table-column field="organization_name" label="Organization Name" sortable v-slot="props">
        <router-link :to="{ name: 'organization', params: { id: props.row.organization_id } }">
          {{ props.row.organization_name }}
        </router-link>
      </b-table-column>

      <b-table-column field="group_name" label="Group Name" sortable v-slot="props">
        <router-link :to="{ name: 'group', params: { id: props.row.group_id } }">
          {{ props.row.group_name }}
        </router-link>
      </b-table-column>

      <b-table-column field="label" label="Device Label" sortable v-slot="props">
        <router-link :to="{ name: 'device', params: { id: props.row.id } }">
          {{ props.row.label }}
        </router-link>
      </b-table-column>

      <b-table-column field="name" label="Device Name" sortable v-slot="props">
        <router-link :to="{ name: 'device', params: { id: props.row.id } }">
          <div class="ellipsis" :title="props.row.name">
            {{ props.row.name }}
          </div>
        </router-link>
      </b-table-column>

      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column" :sortable="column.sortable">
          <template v-if="column.searchable && !column.numeric" #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Search..."
              icon="magnify"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row[column.field] }}
          </template>
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  props: {
    data: { default: [] },
    loading: { default: false },
    billing_period: { default: '' },
    billing_currency: { default: false },
  },
  data() {
    return {
      columns: [
        {
          field: 'cloud_service',
          label: 'HARDWARIO Cloud Service" sortable',
        },
        {
          field: 'cloud_price',
          label: 'HARDWARIO Cloud Price" sortable',
        },
        {
          field: 'insight_service',
          label: 'HARDWARIO Insight Service" sortable',
        },
        {
          field: 'insight_price',
          label: 'HARDWARIO Insight Price" sortable',
        },
        {
          field: 'rental_service',
          label: 'HARDWARIO Rental Service" sortable',
        },
        {
          field: 'rental_price',
          label: 'HARDWARIO Rental Price" sortable',
        },
        {
          field: 'billing_period',
          label: 'Billing Period"',
        },
        {
          field: 'period_start',
          label: 'Billing Period Start" sortable',
        },
        {
          field: 'period_end',
          label: 'Billing Period End" sortable',
        },
        {
          field: 'currency',
          label: 'Billing Currency"',
        },
        {
          field: 'note',
          label: 'Note" sortable',
        },
      ],
    };
  },
};
</script>
<style scoped>
div.ellipsis {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 1px solid #000000; */
}
</style>
