<template>
  <div class="section is-main-section">
    <form v-on:submit.prevent="save" class="column max">
      <b-field label="ID" v-if="contract.id" horizontal>
        {{ contract.id }}
      </b-field>

      <b-field label="Created at" v-if="contract.created_at" horizontal>
        {{ contract.created_at | moment('YYYY.MM.DD H:mm:ss') }}
      </b-field>

      <b-field label="Updated at" v-if="contract.updated_at" horizontal>
        {{ contract.updated_at | moment('YYYY.MM.DD H:mm:ss') }}
      </b-field>

      <b-field label="Name" horizontal>
        <b-input v-if="isEdit" v-model="contract.name" required type="text"></b-input>
        <span v-else>{{ contract.name }}</span>
      </b-field>

      <b-field label="Internal Owner" horizontal>
        <b-input v-if="isEdit" v-model="contract.owner" type="text"></b-input>
        <span v-else>{{ contract.owner }}</span>
      </b-field>

      <b-field label="Customer Name" horizontal>
        <b-input v-if="isEdit" v-model="contract.customer_name" type="text"></b-input>
        <span v-else>{{ contract.customer_name }}</span>
      </b-field>

      <b-field label="VAT ID" horizontal>
        <b-input v-if="isEdit" v-model="contract.vat_id" required type="text"></b-input>
        <span v-else>{{ contract.vat_id }}</span>
      </b-field>

      <b-field label="Effective Since" horizontal>
        <Datepicker v-if="isEdit" v-model="contract.effective_since" :max-date="contract.effective_until"></Datepicker>
        <span v-else>{{ contract.effective_since }}</span>
      </b-field>

      <b-field label="Effective Until" horizontal>
        <Datepicker v-if="isEdit" v-model="contract.effective_until" :min-date="contract.effective_since"></Datepicker>
        <span v-else>{{ contract.effective_until }}</span>
      </b-field>

      <b-field label="Billing Currency" horizontal>
        <b-select v-if="isEdit" placeholder="Select ..." v-model="contract.billing_currency" required>
          <option v-for="option of currencyOprions" :value="option" :key="option">
            {{ option }}
          </option>
        </b-select>
        <span v-else>{{ contract.billing_currency }}</span>
      </b-field>

      <b-field label="Billing Period" horizontal>
        <b-select v-if="isEdit" placeholder="Select ..." v-model="contract.billing_period" required>
          <option v-for="option of periodOprions" :value="option" :key="option">
            {{ option }}
          </option>
        </b-select>
        <span v-else>{{ contract.billing_period }}</span>
      </b-field>

      <b-field label="Note" horizontal>
        <b-input v-if="isEdit" type="textarea" v-model="contract.note" rows="2"></b-input>
        <vue-simple-markdown v-else :source="contract.note"></vue-simple-markdown>
      </b-field>

      <b-field label="Invoicing Email" horizontal>
        <div v-if="isEdit">
          <div v-for="(email, index) in contract.invoicing_email" :key="index">
            <b-input
              v-model="contract.invoicing_email[index]"
              type="text"
              placeholder="Email..."
              style="width: 300px"
              size="is-small"
              icon-right="mdi mdi-minus-circle"
              icon-right-clickable
              @icon-right-click="removeInvoicingEmailItem(index)"
            >
            </b-input>
          </div>
          <b-button @click="addInvoicingEmail" type="is-info" size="is-small">
            <i class="mdi mdi-plus-circle" title="Add"></i> Add email
          </b-button>
        </div>
        <div v-else>
          <div v-for="(email, index) in contract.invoicing_email" :key="index">
            {{ email }}
          </div>
        </div>
      </b-field>

      <b-field label="Cloud Price" horizontal>
        <b-table :data="contract.cloud_service_list">

          <b-table-column field="name" label="Name" width="350" v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.name" size="is-small"></b-input>
            <span v-else> {{ props.row.name }} </span>
          </b-table-column>

          <b-table-column field="minimum_commitment" label="Minimum commitment (months)" width="100" v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.minimum_commitment" size="is-small"></b-input>
            <span v-else> {{ props.row.minimum_commitment }} </span>
          </b-table-column>

          <b-table-column field="price_per_month" label="Price per month" width="100" v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.price_per_month" size="is-small"></b-input>
            <span v-else> {{ props.row.price_per_month }} </span>
          </b-table-column>

          <b-table-column field="note" label="Note" v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.note" size="is-small"></b-input>
            <span v-else> {{ props.row.note }} </span>
          </b-table-column>

          <b-table-column v-if="isEdit" field="action" v-slot="props">
            <a v-on:click="removeServiceItem(contract.cloud_service_list, props.row)">
              <span class="icon has-text-info">
                <i class="mdi mdi-minus-circle" title="Remove"></i>
              </span>
            </a>
          </b-table-column>

          <template slot="empty">Empty</template>

          <template v-if="isEdit" slot="footer">
            <b-button @click="addServiceItem(contract.cloud_service_list)" type="is-info" size="is-small">
              <i class="mdi mdi-plus-circle" title="Add"></i> Add
            </b-button>
          </template>
        </b-table>
      </b-field>

      <b-field label="Insight Price" horizontal>
        <b-table :data="contract.insight_service_list">

          <b-table-column field="name" label="Name" width="350"  v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.name" size="is-small"></b-input>
            <span v-else> {{ props.row.name }} </span>
          </b-table-column>

          <b-table-column field="minimum_commitment" label="Minimum commitment (months)" width="100"  v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.minimum_commitment" size="is-small"></b-input>
            <span v-else> {{ props.row.minimum_commitment }} </span>
          </b-table-column>

          <b-table-column field="price_per_month" label="Price per month" width="100"  v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.price_per_month" size="is-small"></b-input>
            <span v-else> {{ props.row.price_per_month }} </span>
          </b-table-column>

          <b-table-column field="note" label="Note"  v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.note" size="is-small"></b-input>
            <span v-else> {{ props.row.note }} </span>
          </b-table-column>

          <b-table-column v-if="isEdit" field="action"  v-slot="props">
            <a v-on:click="removeServiceItem(contract.insight_service_list, props.row)">
              <span class="icon has-text-info">
                <i class="mdi mdi-minus-circle" title="Remove"></i>
              </span>
            </a>
          </b-table-column>

          <template slot="empty">Empty</template>

          <template v-if="isEdit" slot="footer">
            <b-button @click="addServiceItem(contract.insight_service_list)" type="is-info" size="is-small">
              <i class="mdi mdi-plus-circle" title="Add"></i> Add
            </b-button>
          </template>
        </b-table>
      </b-field>

      <b-field label="Rental Price" horizontal>
        <b-table :data="contract.rental_service_list">

          <b-table-column field="name" label="Name" width="350"  v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.name" size="is-small"></b-input>
            <span v-else> {{ props.row.name }} </span>
          </b-table-column>

          <b-table-column field="minimum_commitment" label="Minimum commitment (months)" width="100"  v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.minimum_commitment" size="is-small"></b-input>
            <span v-else> {{ props.row.minimum_commitment }} </span>
          </b-table-column>

          <b-table-column field="price_per_month" label="Price per month" width="100"  v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.price_per_month" size="is-small"></b-input>
            <span v-else> {{ props.row.price_per_month }} </span>
          </b-table-column>

          <b-table-column field="note" label="Note"  v-slot="props">
            <b-input v-if="isEdit" v-model="props.row.note" size="is-small"></b-input>
            <span v-else> {{ props.row.note }} </span>
          </b-table-column>

          <b-table-column v-if="isEdit" field="action"  v-slot="props">
            <a v-on:click="removeServiceItem(contract.rental_service_list, props.row)">
              <span class="icon has-text-info">
                <i class="mdi mdi-minus-circle" title="Remove"></i>
              </span>
            </a>
          </b-table-column>

          <template slot="empty">Empty</template>

          <template v-if="isEdit" slot="footer">
            <b-button @click="addServiceItem(contract.rental_service_list)" type="is-info" size="is-small">
              <i class="mdi mdi-plus-circle" title="Add"></i> Add
            </b-button>
          </template>
        </b-table>
      </b-field>

      <b-field label="" horizontal>
        <div class="buttons">
          <b-button v-if="isNew" type="is-success" icon-left="plus-box" native-type="submit" :disabled="isFetching"
            >Add Contract</b-button
          >
          <template v-else-if="!isEdit">
            <b-button type="is-info" icon-left="pencil" :disabled="isFetching" v-on:click="isEdit = true"
              >Edit Contract</b-button
            >
            <router-link :to="{ name: 'contract-report', params: { id: contract.id, reportId: 'preview' } }">
              <b-button type="is-info" icon-left="chart-box-outline">Preview report</b-button>
            </router-link>
          </template>
          <template v-else>
            <b-button type="is-info" icon-left="close" :disabled="isFetching" v-on:click="closeEdit">Close</b-button>
            <b-button type="is-success" icon-left="content-save" native-type="submit" :disabled="isFetching"
              >Save Contract</b-button
            >
            <b-button type="is-danger" v-on:click="showDeleteDialog" icon-left="trash-can" :disabled="isFetching"
              >Delete Contract</b-button
            >
          </template>
        </div>
      </b-field>
    </form>

    <div class="modal is-active" v-if="deleteDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete this contract?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteMe">Delete</button>
          <button class="button" v-on:click="closeDeleteDialog">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
// import { concat } from 'lodash';
// import moment from 'moment';
import Datepicker from '@/components/Datepicker.vue';
import variable from '@/variable';

export default {
  name: 'contract',
  components: { Datepicker },
  data() {
    return {
      isEdit: false,
      contract: {
        invoicing_email: [],
      },
      deleteDialog: false,
      isFetching: false,
      periodOprions: variable.periodList,
      currencyOprions: variable.currencyList,
    };
  },
  created() {
    this.fetch();
    this.isEdit = !this.$route.params.id;
  },
  computed: {
    isNew() {
      return this.contract.id === undefined;
    },
  },
  methods: {
    async fetch() {
      this.isFetching = true;
      const url = this.$route.params.id
        ? `/v1/billing/contract/${this.$route.params.id}`
        : '/v1/billing/contract/default';
      const { data } = await this.$http.get(url);
      this.isFetching = false;
      if (!data.invoicing_email || data.invoicing_email.length === 0) {
        data.invoicing_email = [''];
      }
      this.contract = data;
    },
    async save() {
      this.isFetching = true;

      // const contract = pick(this.contract, ['name', 'vat', 'period', 'currency', 'note']);
      // contract.start_day = this.contract.start_day ? moment(this.contract.start_day).format('YYYY-MM-DD') : null;

      if (this.contract.id) {
        const { data } = await this.$http.put(`v1/billing/contract/${this.contract.id}`, this.contract);
        this.contract = data;
      } else {
        const { data } = await this.$http.post('v1/billing/contracts', this.contract);
        this.contract = data;
        this.$router.push({ name: 'contract', params: { id: data.id } });
      }
      this.isFetching = false;
      this.$toast.success('Contract was saved');
    },
    closeEdit() {
      this.isEdit = false;
      this.fetch();
    },
    async deleteMe() {
      if (!this.deleteDialog) return;
      await this.$http.delete(`/v1/billing/contract/${this.$route.params.id}`);
      this.closeDeleteDialog();
      this.$toast.success('Contract was deleted');
      this.$router.push({ name: 'contracts' });
    },
    showDeleteDialog(e) {
      e.preventDefault();
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = null;
    },
    addInvoicingEmail() {
      this.contract.invoicing_email.push('');
    },
    removeInvoicingEmailItem(index) {
      this.contract.invoicing_email.splice(index, 1);
    },
    addServiceItem(list) {
      list.push({
        name: '',
        minimum_commitment: 0,
        price_per_month: 0,
        note: '',
      });
    },
    removeServiceItem(list, item) {
      const index = list.indexOf(item);
      if (index !== -1) list.splice(index, 1);
    },
  },
};
</script>
